import React, { useContext } from "react";
import { WebsiteContext } from "../../context/AppContext";

const AboutList = ({
  title,
  cover_picture,
  blogData,
  index,
  handleBlogDetails,
  id,
  original_price,
  offer_price
}) => {
  const { isMobileView, color } = useContext(WebsiteContext);
  return (
    <div onClick={()=>handleBlogDetails(id)} style={{cursor:"pointer"}}>
      <div className="blog_content_title" style={{ color: color }}>
        {title}
      </div>
      <div
        className={
          isMobileView ? "blog_content_image_mobile" : "blog_content_image"
        }
      >
        <img src={cover_picture} alt="cover_picture"/>
      </div>
      <div className="blog_content_time_language">
        <s className="blog_content_offer" style={{textDecorationStyle:"dashed"}}>Price: ₹{original_price} </s>
        <div className="blog_content_offer" style={{ color: color }}>
          Offer Price: ₹{offer_price}/- only
        </div>
      </div>
      {index === blogData.length - 1 ? (
        <div style={{marginBottom:60}}></div>
      ) : (
        <div className="blog_border"></div>
      )}
    </div>
  );
};

export default AboutList;
