import React from "react";
import { Helmet } from "react-helmet";

const HelmetComponent = ({ title, name, content }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name={name} content={content} />
      <meta
        name="keywords"
        content="Fluency, Spoken English, English, English master, Vocabulary, Tenses, Verbs, Teaching"
      />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="English" />
      <link rel="canonical" href="https://fluentfable.in" />
      {/* Twitter tags */}
      <meta property="twitter:title" content={title} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:description" content={content} />
      <meta property="twitter:site" content="@fluent-fable" />
      <meta property="twitter:creator" content="@fluent-fable" />
      <meta property="twitter:image" content="https://ibb.co/HTDNwmT" />
      <meta property="twitter:image:width" content="1200" />
      <meta property="twitter:image:height" content="630" />

      {/* Open Graph / Facebook tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={content} />
      <meta property="og:url" content="https://fluentfable.in" /> {/* Update to your site's URL */}
      <meta property="og:site_name" content="Fluent Fable" />
      <meta property="og:image" content="https://ibb.co/HTDNwmT" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
    </Helmet>
  );
};

export default HelmetComponent;
