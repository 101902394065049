import React, { useContext } from "react";
import "../../styles/homepage.css";
import ReviewCard from "../common/ReviewCard";
import manImage from "../../images/man.png";
import girlImage from "../../images/girl.png";
import { WebsiteContext } from "../../context/AppContext";
import "animate.css";

const ReviewSection = () => {
  const { color, isMobileView } = useContext(WebsiteContext);

  const backgroundColors = [
    "#FFDDC1", // light peach
    "#FFABAB", // light pink
    "#FFC3A0", // light coral
    "#D5AAFF", // light purple
    "#A2D2FF", // light blue
    "#B9FBC0", // light green
  ];

  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * backgroundColors.length);
    return backgroundColors[randomIndex];
  };

  const content = [
    {
      name: "Manikandan",
      image: manImage,
      comments:
        "This app has transformed my approach to learning English. The mix of interactive exercises, practical conversations, and grammar tips is perfect for mastering the language. Fluent Fable truly lives up to its name!",
    },
    {
      name: "Keerthiga",
      image: girlImage,
      comments:
        "I appreciate the structured approach of Fluent Fable, but the app could use more advanced practice opportunities for fluent speakers looking to refine their skills further. It’s great for beginners and intermediates.",
    },
    {
      name: "Ragav",
      image: manImage,
      comments:
        "I love how Fluent Fable blends structured lessons with practical, real-world scenarios. The spoken English practice has significantly boosted my confidence. Highly recommend for anyone serious about improving their English!",
    },
  ];
  return (
    <div
      style={{
        textAlign: "center",
        color: color,
        fontFamily: "Nexa-bold",
        fontSize: isMobileView ? 34 : 36,
        marginTop: isMobileView ? 40 : 60,
      }}
    >
      {" "}
      <h3
        style={{
          display: "flex",
          gap: 10,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Love to hear <div className="animate__animated animate__pulse animate__infinite	infinite">❤️</div>
      </h3>
      <div
        className={
          isMobileView ? "review_container_mobile" : "review_container"
        }
      >
        {content?.map((data, index) => {
          const backgroundColor = getRandomColor();
          return (
            <ReviewCard
              name={data?.name}
              comments={data?.comments}
              image={data?.image}
              key={index}
              backgroundColor={backgroundColor}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ReviewSection;
