import React, { Fragment, useContext, useState, useEffect } from "react";
import Logo from "../../images/logo1.png";
import { MdDarkMode } from "react-icons/md";
import { MdOutlineLightMode } from "react-icons/md";
import { motion } from "framer-motion";
import "./navBar.css";
import { WebsiteContext } from "../../context/AppContext";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { IoCloseCircleOutline } from "react-icons/io5";

const NavBarComponent = () => {
  const { dark, handleThemeMode, color, handleRoute, isMobileView } =
    useContext(WebsiteContext);
  // console.log("isMobileView", isMobileView);
  const [modalMenu, setModalMenu] = useState(false);

  useEffect(() => {
    if (modalMenu) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [modalMenu]);

  const handleMenu = () => {
    setModalMenu(!modalMenu);
  };

  const handleMobileRoute = (type) => {
    handleRoute(type);
    if(modalMenu){
      setModalMenu(!modalMenu);
    }
   
  };
  return (
    <Fragment>
      <div
        className={
          isMobileView ? "navbar_container_mobile" : "navbar_container"
        }
        style={{
          background: dark ? "rgb(13, 13, 13, 0.9)" : "rgb(255, 255, 255, 0.9)",
        }}
      >
        <div className="navbar_left">
          <img
            src={Logo}
            alt="Logo"
            onClick={
              isMobileView
                ? () => handleMobileRoute("Home")
                : () => handleRoute("Home")
            }
          />
        </div>
        {isMobileView ? (
          <div
            className="navbar_right"
            style={{ marginRight: 2 }}
            onClick={handleMenu}
          >
            {modalMenu ? (
              <IoCloseCircleOutline
                size={35}
                color={dark ? "#fff" : "#0d0d0d"}
              />
            ) : (
              <HiOutlineMenuAlt3 size={35} color={dark ? "#fff" : "#0d0d0d"} />
            )}
          </div>
        ) : (
          <div className="navbar_right">
            <div
              className="nav_routes"
              style={{ color: color }}
              onClick={() => handleRoute("Home")}
            >
              Home
            </div>
            <div
              className="nav_routes"
              style={{ color: color }}
              onClick={() => handleRoute("About")}
            >
              About
            </div>
            <div
              className="nav_routes"
              style={{ color: color }}
              onClick={() => handleRoute("Blog")}
            >
              Blog
            </div>
            <div
              className="nav_routes"
              style={{ color: color }}
              onClick={() => handleRoute("Contact")}
            >
              Contact
            </div>
            <div className="nav_routes" style={{ color: color }}
            onClick={() => handleRoute("GettheApp")}>
              Get the App
            </div>
            <div onClick={handleThemeMode} style={{ cursor: "pointer" }}>
              <motion.div
                key={dark ? "dark" : "light"}
                initial={{ opacity: 0.5 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.2 }}
                style={{ marginTop: 5 }}
              >
                {dark ? (
                  <MdOutlineLightMode size={24} color="#fff" />
                ) : (
                  <MdDarkMode size={24} />
                )}
              </motion.div>
            </div>
          </div>
        )}
      </div>
      {modalMenu && (
        <div
          className="animate__animated animate__slideInLeft animate__faster mobileMenu"
          style={{ background: dark ? "#0d0d0d" : "#f1faee", color: color }}
        >
          <div
            className="mobile_menu_item"
            style={{ marginTop: 40 }}
            onClick={() => handleMobileRoute("Home")}
          >
            Home
          </div>
          <div
            className="mobile_menu_item"
            onClick={() => handleMobileRoute("About")}
          >
            About
          </div>
          <div
            className="mobile_menu_item"
            onClick={() => handleMobileRoute("Blog")}
          >
            Blog
          </div>
          <div
            className="mobile_menu_item"
            onClick={() => handleMobileRoute("Contact")}
          >
            Contact
          </div>
          <div className="mobile_menu_item" onClick={() => handleMobileRoute("GettheApp")}>
            Get the app!
          </div>
          {/*<div className="time_to_learn_container">
            <div className="time_text" style={{color:color}}>TIME</div>
            <div className="time_text" style={{color:color}}>TO</div>
            <div className="time_text" style={{color:color}}>LEARN</div>
          </div> */}
        </div>
      )}
    </Fragment>
  );
};

export default NavBarComponent;
