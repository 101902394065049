import React, { Fragment, useContext } from "react";
import Logo from "../../images/logo1.png";
import googlePlayStore from "../../images/mobilegoogleplay.webp";
import "./footer.css";
import { WebsiteContext } from "../../context/AppContext";
import { RiInstagramFill } from "react-icons/ri";
import { TfiYoutube } from "react-icons/tfi";
import BorderLine from "../common/BorderLine";

const FooterComponent = () => {
  const { color, dark, handleRoute, isMobileView } = useContext(WebsiteContext);
  const handleSocialRoute = (type) => {
    switch (type) {
      case "Youtube":
        window.open("https://www.youtube.com/@reena_english", "_blank");
        break;
      case "Instagram":
        window.open(
          "https://www.instagram.com/english_with_reena/?igshid=NzZlODBkYWE4Ng==",
          "_blank"
        );
        break;
      default:
        break;
    }
  };

  return (
    <Fragment>
      <BorderLine />
      <div
        className={!dark ? "footer_container_light" : "footer_container_dark"}
      >
        <div className="footer_left">
          <img
            src={Logo}
            alt="logo"
            height={80}
            width={80}
            onClick={() => handleRoute("Home")}
          />
          <div className="footer_left_content">
            <div className="footer_title" style={{ color: color }}>
              Fluent Fable
            </div>
          </div>
        </div>
        <div className="footer_playstore">
          <img src={googlePlayStore} alt="playstore" height={40} width={130} onClick={() => handleRoute("GettheApp")}/>
          {/* <img src={googlePlayStore} alt="playstore" height={40} width={130} />*/}
        </div>
        <div className="bar_line"> </div>
        <div className="footer_center">
          <div className="footer_copy_rights" style={{ color: color }}>
            Copyrights @2024
          </div>
          <div className="footer_slogan" style={{ color: color }}>
            Small Step 🤏 - Big Change ✌️{" "}
          </div>
        </div>
        <div className="footer_social">
        <div className={isMobileView ? "bar_line1_mobile":"bar_line1"} />

          <div className="footer_social_icon">
            <div
              style={{ color: color }}
              className="footer_social_1"
              onClick={() => handleSocialRoute("Instagram")}
            >
              <RiInstagramFill />
            </div>
            <div
              style={{ color: color }}
              className="footer_social_1"
              onClick={() => handleSocialRoute("Youtube")}
            >
              <TfiYoutube />
            </div>
            {/*<div style={{ color: color }} className="footer_social_1" onClick={()=>handleSocialRoute("Telegram")}>
              <FaTelegram />
            </div> */}
          </div>
          <div className={isMobileView ? "bar_line2_mobile":"bar_line2"} />
        </div>
        <div className="footer_right">
          <div className="footer_company">
            <div
              style={{ color: color }}
              className="footer_company_1"
              onClick={() => handleRoute("Contact")}
            >
              Contact
            </div>
            <div
              style={{ color: color }}
              className="footer_company_1"
              onClick={() => handleRoute("Privacy_policy")}
            >
              Privacy Policy
            </div>
            <div
              style={{ color: color }}
              className="footer_company_1"
              onClick={() => handleRoute("Terms_of_service")}
            >
              Terms of Service
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FooterComponent;
